import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { greyDark } from "theme/colors";

const PaymentReminder = ({ onClose, onCompletePayment }) => {
  const authData = useSelector((state) => state.Auth.authData);
  const { t } = useTranslation();
  return (
    !authData?.paymentStatus && (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1000px",
          position: "fixed",
          top: 65,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2
        }}>
        <Alert
          sx={{
            background: "#fff8b2",
            "& .MuiAlert-icon": {
              color: "red"
            }
          }}
          severity='warning'
          action={
            <Box sx={{ display: "flex" }}>
              <Button size='large' onClick={onCompletePayment} variant='contained' sx={{ mr: 2, whiteSpace: "nowrap" }}>
                {t("button.completeBtnText")}
              </Button>
              <IconButton aria-label='close' color='inherit' size='small' onClick={onClose}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Box>
          }>
          <Typography variant='body2' sx={{ color: greyDark[50], fontWeight: 500 }}>
            {authData?.isTrialExpired ? t("err.trailExpire") : t("err.pendingPayment")}
          </Typography>
        </Alert>
      </Box>
    )
  );
};

export default PaymentReminder;
